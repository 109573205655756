import styled from 'styled-components';

import { media } from 'styles/utils';
import { Button } from 'common/interaction';
import ArrowLeft from 'vectors/arrowLeft.svg';

export const StyledButton = styled(Button)`
  align-self: end;
  width: 90%;
  margin: 0 auto;

  ${media.desktop`
    width: 100%;
  `}
`;

export const StyledArrow = styled(ArrowLeft)`
  &:hover {
    cursor: pointer;
  }

  ${media.desktop`
    display: none;
  `}
`;

export const Header = styled.div`
  padding: 100px 24px 0;
  align-self: center;
  color: ${({ theme }) => theme.colors.white};

  ${media.desktop`
    display: none;
  `}
`;

export const HeadingContainer = styled.div`
  padding: 0px 24px;

  ${media.desktop`
    padding: 0;
    margin-bottom: 65px;
  `}
`;

export const ButtonContainer = styled.div`
  padding-bottom: 32px;
`;

export const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.gray.light};
`;

export const Container = styled.div`
  display: grid;
  grid-template-rows: 0.1fr auto 0.1fr;
  height: 100vh;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 32vw;
  `}
`;
